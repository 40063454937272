import { Component, OnInit, Inject, DoCheck, ViewChild, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatAutocomplete, MatAutocompleteTrigger, MatChipInputEvent, MatAutocompleteSelectedEvent } from "@angular/material";
import { FormGroup, FormControl, Validators, NgForm } from "@angular/forms";
import { PersonOfInterest } from "src/app/models/person-of-interest.model";
import { DialogService } from "src/app/services/dialog.service";
import { COMMA, ENTER, T } from "@angular/cdk/keycodes";
import { DriverService } from "src/app/services/driver.service";
import { RequiredFieldsService } from "src/app/services/required-fields.service";
import { CommonService } from "src/app/services/common.service";
import { NotificationService } from "src/app/services/notification.service";
import { Base64Service } from "src/app/services/base64.service";
import { PickList } from "src/app/models/pickList.model";
import { GridApi } from "ag-grid-community";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { HyperLinkComponent } from "src/app/hyperlink.component";
import { Driver } from "src/app/models/driver.model ";
import { ViewPhotoModalComponent } from "src/app/renderer/view-photo/view-photo-modal.component";
import { Attachments } from "src/app/models/attachments.model";
import { Photos } from "src/app/models/photo.model";
import { UserService } from "src/app/services/user.service";
import { NgxImageCompressService } from "ngx-image-compress";
import { cloneDeep } from "lodash";
import { SearchService } from 'src/app/services/search.service';
import { Phone } from "src/app/models/phone.model";
import { AddressModalComponent } from "src/app/renderer/address/address.modal.component";
import { Address } from "src/app/models/address.model";
import { Notes } from "src/app/models/note.model";
import { SliderRendererComponent } from "src/app/renderer/slider-renderer.component";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-update-driver",
  templateUrl: "./update-driver-modal.component.html",
  styleUrls: ["./update-driver-modal.component.css"],
})
export class UpdateDriverModalComponent implements OnInit {
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  emailFormControl = new FormControl("", [
    Validators.email,
    Validators.pattern(this.emailPattern),
  ]);
  protected step = 0;
  protected driver: Driver;
  protected countryList: PickList[];
  protected localGroupingList: PickList[];
  protected statusList: PickList[];
  protected stateList: Array<String>;
  protected cityList: Array<String>;
  protected zipList: Array<String>;
  protected phoneTypeList: any[] = [];
  protected phonelist: any;
  protected certificationList: any[] = [];
  protected selfCertificationlist: any;
  protected dlTypeStateList: any[] = [];
  protected dlStatelist: any;
  protected dlTypeClasificationList: any[] = [];
  protected dlClasificationlist: any;
  protected dlTypeRendorsementList: any[] = [];
  protected dlRendorsementlist: any;
  protected dlTypeRestrictionsList: any[] = [];
  protected dlRestrictionslist: any;
  protected resourceTypeList: any[] = [];
  protected resourcelist: any;

  protected notesGridOptions: Object;
  notesRowData: Array<Notes> = [];
  protected newNotes: string = "";
  protected newNotesArray: Array<string> = [];
  protected selectedNoteRowIndex: number;
  protected rowClassRulesNotes;
  protected notesGridApi: GridApi;
  protected notesRowSelected: boolean = false;

  protected addedAliases: Array<String> = [];
  protected alias: string;
  protected restricted: boolean = false;
  protected isRestrictedReasonloading: boolean = false;
  protected isGenderLoading: boolean = false;
  protected gridApi: GridApi;
  protected phone: Phone = new Phone();
  protected addressGridApi: GridApi;
  protected addressRowData: Array<Address> = [];
  protected defaultImageIndex = undefined;
  protected maxDate;
  protected deleted: boolean = false;
  protected birthYearRange: Array<number>;
  private minYear = 1900;
  private maxYear = new Date().getFullYear();

  protected birthMonthRange: Array<string>;
  protected birthDayRange: Array<number>;

  viewOutput: MatDialogRef<ViewPhotoModalComponent>; // set values photo as default or Remove photo

  frameworkComponents: any;
  rowDataClicked = {};
  primaryPhotoId: number = 1;
  photoArray: Photos[] = [];

  path: string;
  attachmentArray: Array<Attachments> = [];
  aliasesArray: Array<{
    Active: number;
    AliasID: number;
    Name: string;
    UserID: number;
  }> = [];

  rowData: Array<Attachments> = [];
  protected fileNames: String[] = [];
  protected newPhotoArray: Photos[] = [];
  protected newAttachmentArray: Attachments[] = [];

  protected selectedAddressIndex: number;
  protected addressRowSelected: boolean = false;
  protected rowClassRules;
  protected ssnDuplicate: boolean = false;

  protected roleItems: Array<{
    TransportationPeopleDLEndorsementID: number;
    DLEndorsementID: number;
    DLEndorsementName: string;
    Active: number;
  }> = [];
  public chipSelectedRole: Array<{
    TransportationPeopleDLEndorsementID: number;
    DLEndorsementID: number;
    DLEndorsementName: string;
    Active: number;
  }> = ([] = []);
  protected roleBasket: Array<{
    TransportationPeopleDLEndorsementID: number;
    DLEndorsementID: number;
    DLEndorsementName: string;
    Active: number;
  }> = [];
  protected originalRoleBasket: Array<{
    TransportationPeopleDLEndorsementID: number;
    DLEndorsementID: number;
    DLEndorsementName: string;
    Active: number;
  }> = [];
  protected dLEndorsementArray: Array<{
    TransportationPeopleDLEndorsementID: number;
    DLEndorsementID: number;
    DLEndorsementName: string;
    Active: number;
  }> = [];

  public dLRestrictionsArray: Array<{
    TransportationPeopleDLRestrictionID: number;
    DLRestrictionID: number;
    DLRestrictionName: string;
    Active: number;
  }>;
  protected restrictionItems: Array<{
    TransportationPeopleDLRestrictionID: number;
    DLRestrictionID: number;
    DLRestrictionName: string;
    Active: number;
  }> = [];
  public chipSelectedRestriction: Array<{
    TransportationPeopleDLRestrictionID: number;
    DLRestrictionID: number;
    DLRestrictionName: string;
    Active: number;
  }> = ([] = []);
  protected restrictionBasket: Array<{
    TransportationPeopleDLRestrictionID: number;
    DLRestrictionID: number;
    DLRestrictionName: string;
    Active: number;
  }> = [];
  protected originalRestrictionBasket: Array<{
    TransportationPeopleDLRestrictionID: number;
    DLRestrictionID: number;
    DLRestrictionName: string;
    Active: number;
  }> = [];

  protected driverArray: any;
  public filteredRoles: Observable<String[]>;
  private allowFreeTextAddUser = false;
  public roleControl = new FormControl([]);
  public filteredRestrictions: Observable<String[]>;
  public restrictionControl = new FormControl([]);
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild("roleInput") userInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("trigger") autoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild("restrictionInput") restrictionInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto2") matAutocomplete2: MatAutocomplete;
  @ViewChild("trigger2") autoCompleteTrigger2: MatAutocompleteTrigger;


  @ViewChild("formRef") updatePoiForm: NgForm;
  localControl = new FormControl();
  filteredLocal: Observable<any[]>;
  statusControl = new FormControl();
  filteredStatus: Observable<any[]>;
  resourceTypeControl = new FormControl();
  filteredResourceType: Observable<any[]>;
  dLStateControl = new FormControl();
  filteredDLState: Observable<any[]>;
  dLClassificationControl = new FormControl();
  filteredDLClassification: Observable<any[]>;
  selfCertificationControl = new FormControl();
  filteredSelfCertification: Observable<any[]>;
  phoneTypeControl = new FormControl();
  filteredPhone: Observable<any[]>;
  protected phoneData: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Driver,
    protected dialogRef: MatDialogRef<UpdateDriverModalComponent>,
    protected dialog: MatDialog,
    protected requiredFieldService: RequiredFieldsService,
    protected driverService: DriverService,
    protected commonService: CommonService,
    protected dialogService: DialogService,
    protected notificationService: NotificationService,
    protected base64Service: Base64Service,
    protected userService: UserService,
    protected imageCompress: NgxImageCompressService,
    protected searchService: SearchService,
    protected datePipe: DatePipe
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      hyperLinkComponent: HyperLinkComponent,
      sliderRenderer: SliderRendererComponent,
    };
    this.rowClassRules = {
      "row-ignore": (params) => {
        var condition = params.data.Default === true;
        return condition;
      }
    }

    this.rowClassRulesNotes = {
      "row-inactive": (params) => {
        var condition = params.data.Active === false;
        return condition;
      },
    };
    this.notesGridOptions = {
      autoSizeStrategy: {
        type: 'fitGridWidth',
        defaultMinWidth: 100
      }
    }
    this.step = 0;
    this.restricted = false;

    this.birthYearRange = [];

    // populating the birth year range array []
    for (let i = this.minYear; i <= this.maxYear; i++) {
      this.birthYearRange[i - this.minYear] = i;
    }

    // birth month range
    this.birthMonthRange = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.birthDayRange = [];

    // populating birth day range
    for (let i = 0; i <= 30; i++) {
      this.birthDayRange[i] = i + 1;
    }
  }
  notesColumnDefs = [

    {
      headerName: "Created Date",
      field: "CreatedDate",
      checkboxSelection: true,
      hide: false,
      cellRenderer: (data) => {
        const formattedDate = this.datePipe.transform(data.value, "MM/dd/yyyy h:mm a");
        return formattedDate ? formattedDate : null;
      },
      sortable: true,
      resizable: true,
      width: 90,
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      hide: false,
      sortable: true,
      resizable: true,
      width: 130,

    },
    {
      headerName: "Note",
      field: "Note",
      resizable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { "white-space": "normal" }
    },

  ]
  addressColumnDefs = [


    {
      headerName: "",
      checkboxSelection: true,
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      resizable: true,
      width: 30,
    },
    {
      headerName: "Address Type",
      field: "AddressType",
      cellStyle: { color: "#000", 'text-transform': 'uppercase' },
      hide: false,
      sortable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "AddressType"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      resizable: true,
      width: 100,
    },
    {
      headerName: "Default",
      field: "Default",
      cellStyle: { 'text-transform': 'uppercase' },
      resizable: true,
      cellRenderer: (data) => {
        if (data.value) {
          return "Yes";
        } else {
          return "No";
        }
      },
      width: 70,
    },
    {
      headerName: "Address",
      field: "Address",
      cellStyle: { color: "#000", 'text-transform': 'uppercase' },
      hide: false,
      sortable: true,
      resizable: true,
      width: 267,
    },
    {
      headerName: "City",
      field: "City",
      hide: false,
      cellStyle: { 'text-transform': 'uppercase' },
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "City"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      width: 100,
    },
    {
      headerName: "State",
      field: "State",
      hide: false,
      cellStyle: { 'text-transform': 'uppercase' },
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "State"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      width: 100,
    },
    {
      headerName: "Zip",
      field: "Zip",
      hide: false,
      cellStyle: { 'text-transform': 'uppercase' },
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "Zip"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      width: 100,
    },
    {
      headerName: "Country",
      field: "Country",
      hide: false,
      cellStyle: { 'text-transform': 'uppercase' },
      sortable: true,
      resizable: true,
      cellRenderer: (data) => {
        if (data) {
          let addressType = this.commonService.getPickListData(
            this.searchService.getTableName(),
            "Country"
          ).filter((e) => e.PickListID == +data.value)
            .map((e) => e.DisplayValue)[0];
          return addressType ? addressType : null;
        }
      },
      width: 100,
    },

  ];

  columnDefs = [
    {
      headerName: "Name",
      field: "BaseName",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 200,
    },
    {
      headerName: "Name",
      field: "LogicalFileName",
      cellStyle: { color: "#000" },
      hide: true,
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Description",
      field: "Description",
      cellStyle: { color: "#000" },
      hide: false,
      sortable: true,
      editable: true,
      resizable: true,
      width: 467,
    },
    {
      headerName: "",
      cellRenderer: "hyperLinkComponent",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    },

    {
      headerName: "",
      cellRenderer: "buttonRenderer",
      resizable: true,
      cellRendererParams: {
        onClick: this.onBtnClick.bind(this),
        label: "Remove",
      },
      width: 100,
    },
    {
      headerName: "",
      field: "Active",
      resizable: true,
      hide: true,
    },
  ];

  onBtnClick(e) {
    this.rowDataClicked = e.rowData;
  }
  ngOnInit(): void {

    this.driver = { ...this.data };

    this.localGroupingList = this.commonService.getPickListData(
      this.searchService.getTableName(),
      "Local399Grouping"
    );
    if (this.localGroupingList && this.localGroupingList.length > 0) {
      this.filteredLocal = this.localControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filteredLocal(DisplayValue) : this.localGroupingList.slice())
      );
    }
    this.statusList = this.commonService.getPickListData(
      this.searchService.getTableName(),
      "Status"
    );
    if (this.statusList && this.statusList.length > 0) {
      this.filteredStatus = this.statusControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filteredStatus(DisplayValue) : this.statusList.slice())
      );
    }
    this.resourcelist = this.commonService.getPickListData(
      "TransportationPeople",
      "ResourceType"
    );
    if (this.resourcelist && this.resourcelist.length > 0) {
      this.filteredResourceType = this.resourceTypeControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filteredResourceType(DisplayValue) : this.resourcelist.slice())
      );
    }

    this.dlStatelist = this.commonService.getPickListData(
      "TransportationPeople",
      "DLState"
    );
    if (this.dlStatelist && this.dlStatelist.length > 0) {
      this.filteredDLState = this.dLStateControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filteredDLState(DisplayValue) : this.dlStatelist.slice())
      );
    }
    this.dlClasificationlist = this.commonService.getPickListData(
      "TransportationPeople",
      "DLClassification"
    );
    if (this.dlClasificationlist && this.dlClasificationlist.length > 0) {
      this.filteredDLClassification = this.dLClassificationControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filteredDLClassification(DisplayValue) : this.dlClasificationlist.slice())
      );
    }
    this.selfCertificationlist = this.commonService.getPickListData(
      "TransportationPeople",
      "SelfCertification"
    );
    if (this.selfCertificationlist && this.selfCertificationlist.length > 0) {
      this.filteredSelfCertification = this.selfCertificationControl.valueChanges.pipe(
        startWith(""),
        map(value => typeof value === 'string' ? value : value?.DisplayValue),
        map(DisplayValue => DisplayValue ? this._filteredSelfCertification(DisplayValue) : this.selfCertificationlist.slice())
      );
    }

    this.dlRendorsementlist = this.commonService.getPickListData(
      "TransportationPeople",
      "DLEndorsement"
    );
    this.dlRestrictionslist = this.commonService.getPickListData(
      "TransportationPeople",
      "DLRestrictions"
    );
    if (this.driverArray) {
      if (this.dlRendorsementlist.length > 0) {
        this.prepareRoleList();
      }
      if (this.dlRestrictionslist.length > 0) {
        this.prepareRestrictionList();
      }
    }


    this.driverService.getTransportationPeople(this.driver.TransportationPeopleID).then(() => {
      this.driver = this.driverService.driver[0];
      this.driverArray = this.driverService.driver[0];
      if (this.driverArray) {
        if (this.dlRendorsementlist.length > 0) {
          this.prepareRoleList();
        }
        if (this.dlRestrictionslist.length > 0) {
          this.prepareRestrictionList();
        }
      }

      this.addressRowData = this.driver.TransportationPeopleAddress;
      this.notesRowData = this.driver.TransportationPeopleNote;
      if (
        this.driver.PhotosArray.length > 0 ||
        this.driver.AttachmentsArray.length > 0
      ) {
        this.commonService
          .getAllFiles(
            this.driver.PhotosArray.filter((f) => f.Active),
            this.driver.AttachmentsArray.filter((f) => f.Active),
            Number(this.driver.TransportationPeopleID)
          )
          .then(() => {
            this.driver.PhotosArray = this.commonService.allFiles[0].Photos.filter(
              (f) => f.Active
            );
            this.driver.PhotosArray.forEach((element) => {
              element.IsPrimary =
                Number(element.PhotoID) == Number(this.driver.PrimaryPhotoID)
                  ? true
                  : false;
              (element.BaseName = element.LogicalFileName.replace(
                /\.[^/.]+$/,
                ""
              )),
                (element.Ext =
                  element.LogicalFileName.substring(
                    element.LogicalFileName.lastIndexOf(".") + 1,
                    element.LogicalFileName.length
                  ) || "");
            });
            this.driver.AttachmentsArray = this.commonService.allFiles[0].Attachments.filter(
              (f) => f.Active
            );
            this.driver.AttachmentsArray.forEach((element) => {
              (element.BaseName = element.LogicalFileName.replace(
                /\.[^/.]+$/,
                ""
              )),
                (element.Ext =
                  element.LogicalFileName.substring(
                    element.LogicalFileName.lastIndexOf(".") + 1,
                    element.LogicalFileName.length
                  ) || "");
              element.OrginalFileName = element.LogicalFileName;
            });
            this.photoArray = this.driver.PhotosArray;
            var i: number = 0;
            var photo: Photos[] = this.photoArray.filter(
              (f) => f.IsPrimary == true
            );
            if (photo.length > 0) {
              var index = this.photoArray.indexOf(photo[0]);
              this.photoArray.splice(index, 1);
              this.photoArray.unshift(photo[0]);
            }
            this.photoArray.forEach((element) => {
              element.Index = i;
              i++;
            });

          })
          .catch((err) => {
            this.notificationService.failure(err.msg);
          })
          .finally(() => {
          });
      }
    }).catch((err) => {
      this.notificationService.failure(err.msg);
    }).finally(() => {
      this.rowData = this.driver.AttachmentsArray.filter((f) => f.Active);
      this.attachmentArray = this.driver.AttachmentsArray.filter(
        (f) => f.Active
      );
    });


    this.stateList = [];
    this.phoneTypeList = this.commonService.pickList;
    if (this.phoneTypeList) {
      this.phonelist = this.phoneTypeList.filter((e) => { e.TableName === "TransportationPeople" && e.FieldName === "Phone" })
    }
    // this.dlTypeStateList = this.commonService.pickList;
    // if(this.dlTypeStateList){
    //   this.dlStatelist = this.dlTypeStateList.filter((e) =>{ e.TableName == "TransportationPeople" && e.FieldName == "DLState"}  )
    // }
    // this.certificationList = this.commonService.pickList;
    // if(this.certificationList){
    //   this.selfCertificationlist =this.certificationList.filter((e) =>{ e.TableName === "TransportationPeople" && e.FieldName === "SelfCertification"}  )
    // }
    // this.dlTypeClasificationList = this.commonService.pickList;
    // if(this.dlTypeClasificationList){
    //   this.dlClasificationlist =this.dlTypeClasificationList.filter((e) =>{ e.TableName === "TransportationPeople" && e.FieldName === "DLClassification"}  )
    // }
    // this.dlTypeRendorsementList = this.commonService.pickList;
    // if(this.dlTypeRendorsementList){
    //   this.dlRendorsementlist =this.dlTypeRendorsementList.filter((e) =>{ e.TableName === "TransportationPeople" && e.FieldName === "DLEndorsement"}  )
    // }
    // this.dlTypeRestrictionsList = this.commonService.pickList;
    // if(this.dlTypeRestrictionsList){
    //   this.dlRestrictionslist =this.dlTypeRestrictionsList.filter((e) =>{ e.TableName === "TransportationPeople" && e.FieldName === "DLRestrictions"}  )
    // }
    // this.resourceTypeList = this.commonService.pickList;
    // if(this.resourceTypeList){
    //   this.dlRestrictionslist =this.resourceTypeList.filter((e) =>{ e.TableName === "TransportationPeople" && e.FieldName === "ResourceType"}  )
    // }

    if (
      this.commonService.pickList != undefined &&
      this.commonService.pickList.filter(
        (f) => f.TableName === this.searchService.getPageName()
      ).length > 0
    ) {
      this.countryList = this.commonService.getPickListData(
        this.searchService.getTableName(),
        "Country"
      );
      this.localGroupingList = this.commonService.getPickListData(
        this.searchService.getTableName(),
        "Local399Grouping"
      );
      if (this.localGroupingList && this.localGroupingList.length > 0) {
        this.filteredLocal = this.localControl.valueChanges.pipe(
          startWith(""),
          map(value => typeof value === 'string' ? value : value?.DisplayValue),
          map(DisplayValue => DisplayValue ? this._filteredLocal(DisplayValue) : this.localGroupingList.slice())
        );
      }
      this.statusList = this.commonService.getPickListData(
        this.searchService.getTableName(),
        "Status"
      );
      this.countryList.unshift(new PickList());
      this.localGroupingList.unshift(new PickList());
      this.statusList.unshift(new PickList());
    } else {
      this.commonService
        .getPickListApi()
        .then(() => {
          this.phonelist = this.commonService.getPickListData(
            "TransportationPeople",
            "Phone"
          );
          if (this.phonelist && this.phonelist.length > 0) {
            this.filteredPhone = this.phoneTypeControl.valueChanges.pipe(
              startWith(""),
              map(value => typeof value === 'string' ? value : value?.DisplayValue),
              map(DisplayValue => DisplayValue ? this._filteredPhone(DisplayValue) : this.phonelist.slice())
            );
          }

          //for getting phone displayvalue from phonelist
          this.driver.TransportationPeoplePhone.forEach((ele) => {
            const matchingPhone = this.phonelist.find((ele2) => ele.PhoneType === ele2.PickListID);
            if (matchingPhone) {
              ele.DisplayValue = matchingPhone.DisplayValue;
            }
          });
          this.phoneData = this.driver.TransportationPeoplePhone;
          this.selfCertificationlist = this.commonService.getPickListData(
            "TransportationPeople",
            "SelfCertification"
          );
          if (this.selfCertificationlist && this.selfCertificationlist.length > 0) {
            this.filteredSelfCertification = this.selfCertificationControl.valueChanges.pipe(
              startWith(""),
              map(value => typeof value === 'string' ? value : value?.DisplayValue),
              map(DisplayValue => DisplayValue ? this._filteredSelfCertification(DisplayValue) : this.selfCertificationlist.slice())
            );
          }
          this.dlStatelist = this.commonService.getPickListData(
            "TransportationPeople",
            "DLState"
          );
          this.dlClasificationlist = this.commonService.getPickListData(
            "TransportationPeople",
            "DLClassification"
          );
          this.dlRendorsementlist = this.commonService.getPickListData(
            "TransportationPeople",
            "DLEndorsement"
          );
          this.dlRestrictionslist = this.commonService.getPickListData(
            "TransportationPeople",
            "DLRestrictions"
          );
          if (this.driverArray) {
            if (this.dlRendorsementlist.length > 0) {
              this.prepareRoleList();
            }
            if (this.dlRestrictionslist.length > 0) {
              this.prepareRestrictionList();
            }
          }
          this.resourcelist = this.commonService.getPickListData(
            "TransportationPeople",
            "ResourceType"
          );
          if (
            this.commonService.pickList != undefined &&
            this.commonService.pickList.length > 0
          ) {
            this.countryList = this.commonService.getPickListData(
              this.searchService.getTableName(),
              "Country"
            );
            this.localGroupingList = this.commonService.getPickListData(
              this.searchService.getTableName(),
              "Local399Grouping"
            );
            if (this.localGroupingList && this.localGroupingList.length > 0) {
              this.filteredLocal = this.localControl.valueChanges.pipe(
                startWith(""),
                map(value => typeof value === 'string' ? value : value?.DisplayValue),
                map(DisplayValue => DisplayValue ? this._filteredLocal(DisplayValue) : this.localGroupingList.slice())
              );
            }
            this.statusList = this.commonService.getPickListData(
              this.searchService.getTableName(),
              "Status"
            );
            this.countryList.unshift(new PickList());
            this.localGroupingList.unshift(new PickList());
            this.statusList.unshift(new PickList());
          }
        })
        .catch((err) => {
          this.notificationService.failure(err);
        });
    }
  }

  displayFn3(selectedoption: number) {
    if (selectedoption) {
      return this.localGroupingList.find(x => x.PickListID === selectedoption)?.DisplayValue;
    }

  }

  private _filteredLocal(value: string) {
    if (!this.localGroupingList) {
      return []; // Return an empty array if localGroupingList is undefined
    }
    return this.localGroupingList.filter(option =>
      option.DisplayValue && option.DisplayValue.toLowerCase().includes(value.toLowerCase())
    );
  }

  displayFn1(selectedoption: number) {
    if (selectedoption) {
      return this.statusList.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  private _filteredStatus(value: string) {
    //return this.statusList.filter(option => option.DisplayValue.toLowerCase().includes(value.toLowerCase()));
    if (!this.statusList) {
      return []; // Return an empty array if statusList is undefined
    }
    return this.statusList.filter(option =>
      option.DisplayValue && option.DisplayValue.toLowerCase().includes(value.toLowerCase())
    );
  }

  displayFn2(selectedoption: number) {
    if (selectedoption) {
      return this.resourcelist.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  private _filteredResourceType(value: string) {
    if (!this.resourcelist) {
      return []; // Return an empty array if statusList is undefined
    }
    return this.resourcelist.filter(option =>
      option.DisplayValue && option.DisplayValue.toLowerCase().includes(value.toLowerCase())
    );
  }
  displayFn4(selectedoption: number) {
    if (selectedoption) {
      return this.dlStatelist.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  private _filteredDLState(value: string) {
    if (!this.dlStatelist) {
      return []; // Return an empty array if localGroupingList is undefined
    }
    return this.dlStatelist.filter(option =>
      option.DisplayValue && option.DisplayValue.toLowerCase().includes(value.toLowerCase())
    );
  }

  displayFn5(selectedoption: number) {
    if (selectedoption) {
      return this.dlClasificationlist.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  private _filteredDLClassification(value: string) {
    if (!this.dlClasificationlist) {
      return []; // Return an empty array if localGroupingList is undefined
    }
    return this.dlClasificationlist.filter(option =>
      option.DisplayValue && option.DisplayValue.toLowerCase().includes(value.toLowerCase())
    );
  }

  displayFn6(selectedoption: number) {
    if (selectedoption) {
      return this.selfCertificationlist.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  private _filteredSelfCertification(value: string) {
    if (!this.selfCertificationlist) {
      return []; // Return an empty array if localGroupingList is undefined
    }
    return this.selfCertificationlist.filter(option =>
      option.DisplayValue && option.DisplayValue.toLowerCase().includes(value.toLowerCase())
    );
  }

  displayFn7(selectedoption: number) {
    if (selectedoption) {
      return this.phonelist.find(x => x.PickListID === selectedoption).DisplayValue;
    }
  }

  private _filteredPhone(value: string) {
    if (!this.phonelist) {
      return [];
    }
    return this.phonelist.filter(option =>
      option.DisplayValue && option.DisplayValue.toLowerCase().includes(value.toLowerCase())
    );
  }

  ngAfterViewChecked() {
    this.setRequiredFields();
  }

  searchSssn() {
    if (this.isSSNValid()) {
      this.driverService
        .searchSssnAPI(this.driver.SSN)
        .then((response: any) => {
          if (response.body.SSNDuplicateCount > 0) {
            this.ssnDuplicate = true
          } else {
            this.ssnDuplicate = false
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else {
      this.ssnDuplicate = false
    }

  }

  isSSNValid(): boolean {
    const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
    return ssnPattern.test(this.driver.SSN);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setRequiredFields() {
    this.requiredFieldService.setRequiredFields(
      "PersonOfInterest",
      this.updatePoiForm
    );
  }
  onNoteRowSelected(params) {
    const p = {
      rowNodes: [params.node],
      force: true
    };
    this.selectedNoteRowIndex = params.rowIndex;

  }
  selectedNoteRow($event) {
    let selectedRow = $event.api.getSelectedRows();
    if (selectedRow.length) {
      this.notesRowSelected = true;
      if (selectedRow[0].Active) {
        this.deleted = false;
      }
      else {
        this.deleted = true;
      }
    }
    else {
      this.notesRowSelected = false;
    }
  }
  onCellValueChanged(params) {
    if (params.colDef.field === "Active")
      this.removeAttachment(params.node.rowIndex);
    if (params.colDef.field === "BaseName") {
      params.node.setDataValue(
        "LogicalFileName",
        params.data.BaseName + "." + params.data.Ext
      );
    }
    if (params.colDef.field === "LogicalFileName") {
    }
  }
  //adds note to grid on click of Add button
  addNote() {
    this.newNotesArray.push(this.newNotes);
    this.notesRowData = [...this.notesRowData, {
      NoteID: 0, Note: this.newNotes, XID: "", Active: true, CreatedBy: this.userService.firstName
        + " " + this.userService.lastName, CreatedDate: this.datePipe.transform(new Date(), "MM/dd/yyyy h:mm a")
    }];
    this.newNotes = "";
  }
  //makes the selected Note row red and changes status to inactive to be removed
  removeNote() {
    let rowIndex = this.selectedNoteRowIndex;
    this.notesRowData[rowIndex].Active = false;
    this.deleted = true;
    this.notesGridApi.setRowData(this.notesRowData);
    this.notesRowSelected = !this.notesRowSelected;
  }
  //makes the selected Note row the normal color and changes status to active
  undoRemoveNote() {
    let rowIndex = this.selectedNoteRowIndex;
    this.notesRowData[rowIndex].Active = true;
    this.deleted = false;
    this.notesGridApi.setRowData(this.notesRowData);
    this.notesRowSelected = !this.notesRowSelected;
  }
  onAddressCellValueChanged(params) {
    var index: number = 0;
    if (params.colDef.field == "Default") {
      this.addressRowData.forEach(element => {
        if (params.node.rowIndex != index) {
          element.Default = false;
        } else {
          element.Default = true;
        }

        index++;
      });
      this.addressGridApi.setRowData(this.addressRowData);
    }
  }

  onAddressRowSelected(params) {
    var slctdData = this.addressGridApi.getSelectedRows();
    this.selectedAddressIndex = params.rowIndex;
    this.addressRowSelected = !this.addressRowSelected;
  }

  onRowSelected(params) {
    var slctdData = this.gridApi.getSelectedRows();
  }

  onUpdateDriver() {
    this.dialogService
      .openConfirmDialog(
        "Confirm Transportation People Update",
        "Are you sure you want to save the changes ?"
      )
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          var photoCount = 0;
          var attachmentCount = 0;
          this.driver.PhotosArray = [];
          this.driver.AttachmentsArray = [];
          this.newPhotoArray = [];
          this.newAttachmentArray = [];

          this.driver.TransportationPeopleAddress = this.addressRowData;
          if (this.newNotesArray.length > 0) {
            for (var i = 0; i < this.newNotesArray.length; i++) {
              this.driver.TransportationPeopleNote.push({ NoteID: 0, Note: this.newNotesArray[i], XID: "", Active: true, CreatedBy: null, CreatedDate: null });
            }
          }

          //******* DLEndorsement *********
          this.chipSelectedRole.forEach((element) => {
            element.TransportationPeopleDLEndorsementID = 0;
          });
          let roleBasket = this.driverService.getDriverByID(this.driver.TransportationPeopleID)[0].DLEndorsement.map((ur) => ({
            TransportationPeopleDLEndorsementID: ur.TransportationPeopleDLEndorsementID,
            DLEndorsementID: ur.DLEndorsementID,
            DLEndorsementName: ur.DLEndorsementName,
            Active: ur.Active
          }))
          this.originalRoleBasket = roleBasket;
          this.chipSelectedRole.forEach((element) => {
            element.Active = 1;
          });
          this.originalRoleBasket.forEach((element) => {
            var index = -1;
            const length = this.chipSelectedRole.filter(
              (f) => f.DLEndorsementID == element.DLEndorsementID
            ).length;
            if (length > 0)
              index = this.chipSelectedRole.indexOf(
                this.chipSelectedRole.filter(
                  (f) => f.DLEndorsementID == element.DLEndorsementID
                )[0]
              );
            if (index < 0) {
              element.Active = 0;
              this.chipSelectedRole.push(element);
            } else {
              element.Active = 1;
              this.chipSelectedRole.splice(index, 1);
              this.chipSelectedRole.push(element);
            }
          });
          this.driver.DLEndorsement = this.chipSelectedRole;

          //******* DLrestrictions *********
          this.chipSelectedRestriction.forEach((element) => {
            element.TransportationPeopleDLRestrictionID = 0;
          });
          let restrictionBasket = this.driverService.getDriverByID(this.driver.TransportationPeopleID)[0].DLRestrictions.map((ur) => ({
            TransportationPeopleDLRestrictionID: ur.TransportationPeopleDLRestrictionID,
            DLRestrictionID: ur.DLRestrictionID,
            DLRestrictionName: ur.DLRestrictionName,
            Active: ur.Active
          }))
          this.originalRestrictionBasket = restrictionBasket;
          this.chipSelectedRestriction.forEach((element) => {
            element.Active = 1;
          });
          this.originalRestrictionBasket.forEach((element) => {
            var index = -1;
            const length = this.chipSelectedRestriction.filter(
              (f) => f.DLRestrictionID == element.DLRestrictionID
            ).length;
            if (length > 0)
              index = this.chipSelectedRestriction.indexOf(
                this.chipSelectedRestriction.filter(
                  (f) => f.DLRestrictionID == element.DLRestrictionID
                )[0]
              );
            if (index < 0) {
              element.Active = 0;
              this.chipSelectedRestriction.push(element);
            } else {
              element.Active = 1;
              this.chipSelectedRestriction.splice(index, 1);
              this.chipSelectedRestriction.push(element);
            }
          });
          this.driver.DLRestrictions = this.chipSelectedRestriction;

          this.photoArray.forEach((element) => {
            element.Index = photoCount;
            element.LogicalFileName = element.BaseName + "." + element.Ext;
            photoCount++;
          });
          this.attachmentArray.forEach((element) => {
            element.Index = attachmentCount;
            attachmentCount++;
          });
          this.photoArray.forEach((element) => {
            if (
              element.PhotoID > 0 ||
              (element.PhotoID <= 0 && element.Active)
            ) {
              this.driver.PhotosArray.push(element);
            }
          });
          this.photoArray.forEach((element) => {
            if (element.PhotoID <= 0 && element.Active) {
              this.newPhotoArray.push(element);
            }
          });
          this.attachmentArray.forEach((element) => {
            if (
              element.AttachmentID > 0 ||
              (element.AttachmentID <= 0 && element.Active)
            ) {
              this.driver.AttachmentsArray.push(element);
            }
          });

          this.attachmentArray.forEach((element) => {
            if (element.AttachmentID <= 0 && element.Active) {
              this.newAttachmentArray.push(element);
            }
          });

          this.driverService
            .updateDriver(
              this.driver,
              this.newPhotoArray,
              this.newAttachmentArray
            )
            .then()
            .catch((err) => {
              this.notificationService.failure(err);
            })
            .finally(() => {
              this.onNoClick();
            });
        }
      });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  addFieldValue() {
    this.addedAliases.push(this.alias);
    this.aliasesArray.push({
      Name: this.alias,
      AliasID: 0,
      Active: 1,
      UserID: 9,
    });
    this.alias = "";
  }

  onSliderClick(e, x) {
    this.rowDataClicked = e.rowData;
  }

  // Remove Entry Point from UI
  deleteFieldValue(index: number) {
    this.addedAliases = this.addedAliases.filter(
      (e) => e !== this.addedAliases[index]
    );
    if (this.aliasesArray[index].AliasID > 0) {
      this.aliasesArray[index].Active = 0;
    } else {
      this.aliasesArray = this.aliasesArray.filter(
        (e) => e !== this.aliasesArray[index]
      );
    }
  }

  async uploadPhotos(event) {
    var count: number = 0;

    if (event.target.files) {
      for (var i = 0; i < event.target.files.length; i++) {
        count++;
        var orientation = -1;
        const base64: any = await this.base64Service.encodeFileToBase64(
          event.target.files[i]
        );
        await this.imageCompress
          .compressFile(base64, orientation, 32, 32)
          .then((result) => {
            var file = this.dataURLtoFile(
              result,
              "Thumbnail_" + event.target.files[0].name
            );
            this.photoArray.push({
              XID: "",
              Active: true,
              PhotoID: 0,
              Description: "",
              LogicalFileName: event.target.files[i].name,
              S3KeyName: "",
              UserID: this.userService.userID,
              IsPrimary: false,
              SignedURL: base64,
              SignedURLThumbnail: "",
              File: event.target.files[i],
              ThumbnailFile: file,
              Index: 0,
              BaseName: "",
              Ext: "",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    this.photoArray[0].IsPrimary = true;
    var index: number = 0;
    this.photoArray.forEach((element) => {
      element.BaseName = element.LogicalFileName.replace(/\.[^/.]+$/, "");
      element.Ext =
        element.LogicalFileName.substring(
          element.LogicalFileName.lastIndexOf(".") + 1,
          element.LogicalFileName.length
        ) || "";
      element.Index = index;
      index++;
    });
  }
  async addAttachment(event) {
    if (event.target.files) {
      const filesLength: number = event.target.files.length;

      this.rowData = [];
      for (var i = 0; i < filesLength; i++) {
        this.path = "";
        var blob = new Blob([event.target.files[i]]);
        var url = window.URL.createObjectURL(blob);

        await this.attachmentArray.push({
          Active: true,
          AttachmentID: 0,
          Description: "",
          LogicalFileName: event.target.files[i].name,
          S3KeyName: "",
          UserID: this.userService.userID,
          XID: "",
          Path: event.target.files[i],
          SignedURL: url,
          File: event.target.files[i],
          Index: 0,
          BaseName: event.target.files[i].name.replace(/\.[^/.]+$/, ""),
          Ext:
            event.target.files[i].name.substring(
              event.target.files[i].name.lastIndexOf(".") + 1,
              event.target.files[i].name.length
            ) || "",
          OrginalFileName: event.target.files[i].name,
        });
      }
      this.rowData = this.attachmentArray;
      this.gridApi.setRowData(this.rowData);
      this.gridApi.refreshCells();
    }
  }

  viewImg(photo) {
    this.viewOutput = this.dialog.open(ViewPhotoModalComponent, {
      data: photo,
    });
    this.viewOutput.afterClosed().subscribe((action) => {
      if (action) {
        const index = this.photoArray.indexOf(photo);

        if (index !== -1) {
          const oldData = this.photoArray[index];
          if (action === "setPrimayPhoto") {
            this.photoArray.forEach((element) => {
              element.IsPrimary = false;
            });

            this.photoArray.splice(index, 1);

            this.photoArray.unshift({
              XID: oldData.XID,
              Active: oldData.Active,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: true,
              SignedURL: oldData.SignedURL,
              SignedURLThumbnail: oldData.SignedURLThumbnail,
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index: oldData.Index,
              BaseName: oldData.BaseName,
              Ext: oldData.Ext,
            });
            var i: number = 0;
            this.photoArray.forEach((element) => {
              element.Index = i;
              i++;
            });
          } else if (action === "removePhoto") {
            this.photoArray[index] = {
              XID: oldData.XID,
              Active: false,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: oldData.IsPrimary,
              SignedURL: oldData.SignedURL,
              SignedURLThumbnail: oldData.SignedURLThumbnail,
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index: oldData.Index,
              BaseName: oldData.BaseName,
              Ext: oldData.Ext,
            };
            var i: number = 0;
            this.photoArray.forEach((element) => {
              element.Index = i;
              i++;
            });
          } else if (action === "removeDefault") {
            this.photoArray[index] = {
              XID: oldData.XID,
              Active: oldData.Active,
              PhotoID: oldData.PhotoID,
              Description: oldData.Description,
              LogicalFileName: oldData.LogicalFileName,
              S3KeyName: oldData.S3KeyName,
              UserID: this.userService.userID,
              IsPrimary: false,
              SignedURL: oldData.SignedURL,
              SignedURLThumbnail: oldData.SignedURLThumbnail,
              File: oldData.File,
              ThumbnailFile: oldData.ThumbnailFile,
              Index: oldData.Index,
              BaseName: oldData.BaseName,
              Ext: oldData.Ext,
            };
            var i: number = 0;
            this.photoArray.forEach((element) => {
              element.Index = i;
              i++;
            });
          }
        }

        this.photoArray = cloneDeep(this.photoArray);
      }
    });
  }

  //make ag-rid colums fill their container
  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  //get grid
  onGridReady(params) {
    this.gridApi = params.api;
  }
  onNotesGridReady(params) {
    this.notesGridApi = params.api;
  }
  onNotesDataRendered() {
    this.notesGridApi.sizeColumnsToFit();
  }
  onAddressGridReady(params) {
    this.addressGridApi = params.api;
  }


  //sets the default image
  public setDefaultImage(imageIndex: number) {
    this.defaultImageIndex = imageIndex;
  }

  //removes the attachment from the Array

  public removeAttachment(index: number) {
    this.rowData.splice(index, 1);
    this.gridApi.setRowData(this.rowData);
    this.gridApi.refreshCells();
  }

  //Convert base64 to file
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  consolePhotoArray() {
    console.log(this.photoArray);
  }


  addPhoneValue() {
    this.phone.DisplayValue = this.phonelist.find(phone => phone.PickListID === this.phone.PhoneType).DisplayValue
    this.phone.Active = 1;
    this.phone.PhoneID = 0;
    this.phone.XID = "";
    this.driver.TransportationPeoplePhone.push(this.phone);
    this.phone = new Phone();
  }

  // Remove Phone from UI
  deletePhoneValue(index: number) {

    if (this.driver.TransportationPeoplePhone[index].PhoneID == 0) {
      this.driver.TransportationPeoplePhone = this.driver.TransportationPeoplePhone.filter(
        (e) => e !== this.driver.TransportationPeoplePhone[index]
      );
    } else {
      this.driver.TransportationPeoplePhone[index].Active = 0;
    }
  }

  addAddress() {
    // open widget configure dialog
    var address = new Address();
    address.AddressID = 0;
    address.AddressType = 0;
    address.Address = "";
    address.Address2 = "";
    address.Active = true;
    address.Default = false;
    address.City = 0;
    address.Country = 0;
    address.State = 0;
    address.Zip = 0;
    address.isEdit = false;

    const dialogRef = this.dialog
      .open(AddressModalComponent, {
        disableClose: true,
        data: address,
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          if (this.addressRowData.length == 0) data.Default = true;
          this.addressRowData.push(data);
          this.addressGridApi.setRowData(this.addressRowData);
        }


      });
  }

  editAddress() {
    var data = this.addressGridApi.getSelectedRows()[0];
    data.isEdit = true;
    const dialogRef = this.dialog
      .open(AddressModalComponent, {
        disableClose: true,
        data: this.addressGridApi.getSelectedRows()[0],
        //backdropClass: "backdropBackground",
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.addressRowData[this.selectedAddressIndex] = data;
          this.addressGridApi.setRowData(this.addressRowData);
          this.addressRowSelected = !this.addressRowSelected
        } else {
          this.addressRowData[this.selectedAddressIndex] = data;
          this.addressGridApi.setRowData(this.addressRowData);
          this.addressRowSelected = !this.addressRowSelected
        }

      });
  }
  makeDefault(event) {
    var defaultIndex;
    var defaultAddress;
    var index: number = 0;
    this.addressRowData.forEach(element => {
      if (this.selectedAddressIndex != index) {
        element.Default = false;
      } else {
        element.Default = true;
        defaultIndex = index;
        defaultAddress = element;
      }
      index++;
    });
    this.addressRowData.splice(defaultIndex, 1)
    this.addressRowData.unshift(defaultAddress)
    this.addressGridApi.setRowData(this.addressRowData);
    this.addressRowSelected = !this.addressRowSelected;

  }
  panelExpanded() { }

  formatSSNValue(value: string) {
    // Remove any non-alphanumeric characters
    value = value.replace(/\W/g, '');

    //for adding - after 1st 3 & next 2 cahracters
    if (value.length > 3 && value[3] !== '-') {
      value = value.substring(0, 3) + '-' + value.substring(3);
    }
    if (value.length > 6 && value[6] !== '-') {
      value = value.substring(0, 6) + '-' + value.substring(6);
    }
    // for limiting to 9 characters
    this.driver.SSN = value.substring(0, 11);
  }
  formatPhoneValue(value: string, emergencyPhone: boolean) {
    // Remove any non-alphanumeric characters
    value = value.replace(/\W/g, '');
    //for adding - after 1st 3 & next 2 characters
    if (value.length > 3 && value[3] !== '-') {
      value = value.substring(0, 3) + '-' + value.substring(3);
    }
    if (value.length > 7 && value[7] !== '-') {
      value = value.substring(0, 7) + '-' + value.substring(7);
    }
    // Limit to 10 characters
    if (emergencyPhone === true) this.driver.EmgContactPhone = value.substring(0, 12);
    else this.phone.PhoneNum = value.substring(0, 12);
  }
  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }
  // to format date as MM/DD/YYYY if they enter 04042021
  onDateInput(event: any): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length > 2) {
      value = value.replace(/(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3'); // Format as MM/DD/YYYY
    } else if (value.length > 0) {
      value = value.replace(/^(\d{1,2})$/, '$1/'); // Ensure MM/DD format
    }
    input.value = value;
    input.dispatchEvent(new Event('input'));
  }

  private prepareRoleList() {
    this.filteredRoles = this.roleControl.valueChanges.pipe(
      map((roleName) => this.filterOnValueChange(roleName))
    );
    this.roleControl.setValue(null);

    this.roleItems = this.dlRendorsementlist.map((ur) => ({
      TransportationPeopleDLEndorsementID: 0,
      DLEndorsementID: ur.PickListID,
      DLEndorsementName: ur.DisplayValue,
      Active: ur.Active
    }))
      .filter((u) => u.Active == 1);

    this.roleItems.forEach((element) => {
      element.Active = 0;
    });

    let roleBasket = this.driverService.getDriverByID(this.driver.TransportationPeopleID)[0].DLEndorsement.map((ur) => ({
      TransportationPeopleDLEndorsementID: ur.TransportationPeopleDLEndorsementID,
      DLEndorsementID: ur.DLEndorsementID,
      DLEndorsementName: ur.DLEndorsementName,
      Active: ur.Active
    }));
    roleBasket = roleBasket.filter((rb) => rb.Active === 1);
    roleBasket.sort((a, b) =>
      a.DLEndorsementID > b.DLEndorsementID ? 1 : b.DLEndorsementID > a.DLEndorsementID ? -1 : 0
    );
    this.roleBasket = roleBasket;
    this.chipSelectedRole = this.roleBasket;

    this.chipSelectedRole.forEach((element) => {
      element.Active = 1;
    });

    this.chipSelectedRole.forEach((element) => {
      var index = -1;
      const length = this.roleItems.filter(
        (f) => f.DLEndorsementID == element.DLEndorsementID
      ).length;
      if (length > 0)
        index = this.roleItems.indexOf(
          this.roleItems.filter((f) => f.DLEndorsementID == element.DLEndorsementID)[0]
        );
      if (index >= 0) {
        this.roleItems.splice(index, 1);
      }
    });

  }

  prepareRestrictionList() {
    this.filteredRestrictions = this.restrictionControl.valueChanges.pipe(
      map((roleName) => this.filterOnValueChangeDLRestrict(roleName))
    );
    this.restrictionControl.setValue(null);

    this.restrictionItems = this.dlRestrictionslist.map((ur) => ({
      TransportationPeopleDLRestrictionID: 0,
      DLRestrictionID: ur.PickListID,
      DLRestrictionName: ur.DisplayValue,
      Active: ur.Active
    }))
      .filter((u) => u.Active == 1);

    this.restrictionItems.forEach((element) => {
      element.Active = 0;
    });

    let restrictionBasket = this.driverService.getDriverByID(this.driver.TransportationPeopleID)[0].DLRestrictions.map((ur) => ({
      TransportationPeopleDLRestrictionID: ur.TransportationPeopleDLRestrictionID,
      DLRestrictionID: ur.DLRestrictionID,
      DLRestrictionName: ur.DLRestrictionName,
      Active: ur.Active
    }));
    restrictionBasket = restrictionBasket.filter((rb) => rb.Active === 1);
    restrictionBasket.sort((a, b) =>
      a.DLRestrictionID > b.DLRestrictionID ? 1 : b.DLRestrictionID > a.DLRestrictionID ? -1 : 0
    );
    this.restrictionBasket = restrictionBasket;
    this.chipSelectedRestriction = this.restrictionBasket;

    this.chipSelectedRestriction.forEach((element) => {
      element.Active = 1;
    });

    this.chipSelectedRestriction.forEach((element) => {
      var index = -1;
      const length = this.restrictionItems.filter(
        (f) => f.DLRestrictionID == element.DLRestrictionID
      ).length;
      if (length > 0)
        index = this.restrictionItems.indexOf(
          this.restrictionItems.filter((f) => f.DLRestrictionID == element.DLRestrictionID)[0]
        );
      if (index >= 0) {
        this.restrictionItems.splice(index, 1);
      }
    });
  }

  public addRole(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddUser) {
      return;
    }
    if (this.matAutocomplete.isOpen) {
      return;
    }
    // Add our engineer
    const value = event.value;
    if ((value || "").trim()) {
      this.selectRoleByName(value.trim());
    }
    this.resetInputs();
  }

  public removeRole(role): void {
    const index = this.chipSelectedRole.indexOf(role);
    const templateIndex = this.roleItems.indexOf(role);
    if (index >= 0) {
      this.chipSelectedRole.splice(index, 1);
      if (templateIndex < 0) this.roleItems.push(role);
      this.resetInputs();
    }
  }

  public roleSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectRoleByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    this.userInput.nativeElement.value = "";       // clear input element
    this.roleControl.setValue(null);               // clear control value and trigger engineerControl.valueChanges event
  }

  private filterOnValueChange(roleName: string): String[] {
    let result: String[] = [];
    let allRolesLessSelected = this.roleItems.filter(
      (user) => this.chipSelectedRole.indexOf(user) < 0
    );
    if (roleName) {
      result = this.filterRole(allRolesLessSelected, roleName);
    } else {
      result = allRolesLessSelected.map((role) => role.DLEndorsementName);
    }
    return result;
  }

  private filterRole(
    roleList: Array<{
      TransportationPeopleDLEndorsementID: number;
      DLEndorsementID: number;
      DLEndorsementName: string;
      Active: number;
    }> = [],
    roleName: String
  ): String[] {
    let filteredRolesList: Array<{
      TransportationPeopleDLEndorsementID: number;
      DLEndorsementID: number;
      DLEndorsementName: string;
      Active: number;
    }> = ([] = []);
    const filterValue = roleName.toLowerCase();
    let rolesMatchingRoleName = roleList.filter(
      (role) => role.DLEndorsementName.toLowerCase().indexOf(filterValue) === 0
    );
    if (rolesMatchingRoleName.length || this.allowFreeTextAddUser) {
      filteredRolesList = rolesMatchingRoleName;
    } else {
      filteredRolesList = roleList;
    }
    return filteredRolesList.map((role) => role.DLEndorsementName);
  }

  private selectRoleByName(roleName) {
    let foundUser = this.roleItems.filter((role) => role.DLEndorsementName == roleName);
    if (foundUser.length) {
      this.chipSelectedRole.push(foundUser[0]);
    }
  }

  protected selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  onAutocompleteFocus() {
    this.autoCompleteTrigger._onChange("");
    this.autoCompleteTrigger.openPanel();
  }

  //*******************  DL Restriction *******************************

  public addDLRestriction(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddUser) {
      return;
    }
    if (this.matAutocomplete2.isOpen) {
      return;
    }
    const value = event.value;
    if ((value || "").trim()) {
      this.selectDLRestrictionByName(value.trim());
    }
    this.resetInputsDLRestriction();
  }

  public removeDLRestriction(role): void {
    const index = this.chipSelectedRestriction.indexOf(role);
    const templateIndex = this.restrictionItems.indexOf(role);
    if (index >= 0) {
      this.chipSelectedRestriction.splice(index, 1);
      if (templateIndex < 0) this.restrictionItems.push(role);
      this.resetInputsDLRestriction();
    }
  }

  public dLRestrictionSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectDLRestrictionByName(event.option.value);
    this.resetInputsDLRestriction();
  }

  private resetInputsDLRestriction() {
    this.restrictionInput.nativeElement.value = "";
    this.restrictionControl.setValue(null);
  }

  private filterOnValueChangeDLRestrict(roleName: string): String[] {
    let result: String[] = [];
    let allRolesLessSelected = this.restrictionItems.filter(
      (user) => this.chipSelectedRestriction.indexOf(user) < 0
    );
    if (roleName) {
      result = this.filterDLRestriction(allRolesLessSelected, roleName);
    } else {
      result = allRolesLessSelected.map((role) => role.DLRestrictionName);
    }
    return result;
  }

  private filterDLRestriction(
    roleList: Array<{
      TransportationPeopleDLRestrictionID: number;
      DLRestrictionID: number;
      DLRestrictionName: string;
      Active: number;
    }> = [],
    roleName: String
  ): String[] {
    let filteredRolesList: Array<{
      TransportationPeopleDLRestrictionID: number;
      DLRestrictionID: number;
      DLRestrictionName: string;
      Active: number;
    }> = ([] = []);
    const filterValue = roleName.toLowerCase();
    let rolesMatchingRoleName = roleList.filter(
      (role) => role.DLRestrictionName.toLowerCase().indexOf(filterValue) === 0
    );
    if (rolesMatchingRoleName.length || this.allowFreeTextAddUser) {
      filteredRolesList = rolesMatchingRoleName;
    } else {
      filteredRolesList = roleList;
    }
    return filteredRolesList.map((role) => role.DLRestrictionName);
  }

  private selectDLRestrictionByName(roleName) {
    let foundUser = this.restrictionItems.filter((role) => role.DLRestrictionName == roleName);
    if (foundUser.length) {
      this.chipSelectedRestriction.push(foundUser[0]);
    }
  }

  protected selectionMadeDLRestriction(event: Event, trigger2: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger2.openPanel();
  }

  onAutocompleteFocusDLRestriction() {
    this.autoCompleteTrigger2._onChange("");
    this.autoCompleteTrigger2.openPanel();
  }
}